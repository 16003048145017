import * as _sha2 from "@noble/hashes/sha256";
var _sha = "default" in _sha2 ? _sha2.default : _sha2;
import _base from "./base";
var exports = {};
var {
  sha256
} = _sha;
var bs58checkBase = _base;

// SHA256(SHA256(buffer))
function sha256x2(buffer) {
  return sha256(sha256(buffer));
}
exports = bs58checkBase(sha256x2);
export default exports;